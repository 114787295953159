import { render, staticRenderFns } from "./tests.vue?vue&type=template&id=81a4c466&scoped=true&"
import script from "./tests.vue?vue&type=script&lang=ts&"
export * from "./tests.vue?vue&type=script&lang=ts&"
import style0 from "./tests.vue?vue&type=style&index=0&id=81a4c466&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "81a4c466",
  null
  
)

/* vuetify-loader */
import installComponents from "!D:/Workspaces/Evidence/main/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSpacer } from 'vuetify/lib'
import { VToolbar } from 'vuetify/lib'
import { VToolbarSideIcon } from 'vuetify/lib'
import { VToolbarTitle } from 'vuetify/lib'
installComponents(component, {VSpacer,VToolbar,VToolbarSideIcon,VToolbarTitle})


/* hot reload */
if (module.hot) {
  var api = require("D:\\Workspaces\\Evidence\\main\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('81a4c466', component.options)
    } else {
      api.reload('81a4c466', component.options)
    }
    module.hot.accept("./tests.vue?vue&type=template&id=81a4c466&scoped=true&", function () {
      api.rerender('81a4c466', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/tests.vue"
export default component.exports