var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "mp-page",
      class: _vm.bodyColor,
      style: [{ "padding-top": _vm.toolbarVisible ? "65px" : "15px" }]
    },
    [
      _vm.toolbarVisible
        ? _c(
            "v-toolbar",
            {
              staticClass: "elevation-0",
              attrs: {
                color: _vm.bodyColor,
                colot: "white--text",
                app: "",
                dense: ""
              }
            },
            [
              false
                ? _c("v-toolbar-side-icon", {
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        _vm.drawer = !_vm.drawer
                      }
                    }
                  })
                : _vm._e(),
              _c("v-toolbar-title", [_vm._v(_vm._s(_vm.title))]),
              _c("v-spacer"),
              _vm._t("toolbar-buttons")
            ],
            2
          )
        : _vm._e(),
      _c("div", { staticClass: "mp-body", class: _vm.bodyColor }, [
        _c("div", { staticClass: "mp-header" }, [_vm._t("header")], 2),
        _c(
          "div",
          {
            staticClass: "mp-header-extra",
            class: [
              { "mp-hide": _vm.extraReady && !_vm.extraVisible },
              { "mp-show": _vm.extraReady && _vm.extraVisible }
            ]
          },
          [_vm._t("extra")],
          2
        ),
        _c(
          "div",
          { staticClass: "mp-content", staticStyle: { background: "white" } },
          [_vm._t("default")],
          2
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }