






































import Vue from "vue";
import * as models from "@/models";
import { G } from "@/globals";
import { info } from "@/code/api.info";

export default {
  name: "ModernPage",
  components: {},
  props: {
    title: { required: false, default: "[TITLE]", type: String },
    bodyColor: { required: false, default: "accent", type: String },
    toolbarVisible: { required: false, default: true, type: Boolean },
    extraOpen: { required: false, default: false, type: Boolean }
  },
  data: () => ({
    testVal: 200,
    isAuto: true,
    extraVisible: false,
    extraReady: false
  }),
  watch: {
    extraOpen: function(newValue: boolean) {
      this.extraReady = true;
    }
  },
  computed: {
    testComputed: function() {
      return this.isAuto ? "auto" : `${this.testVal}px`;
    }
  },
  created: function() {},
  mounted: function() {},
  methods: {
    // toggleExtra: function() {
    //   this.extraVisible = !this.extraVisible;
    // }
  }
};
